<template>
  <section class="sub-page iframe">
    <top-section
      :navs="navs"
      :title="nav_title"
    />

    <!-- <div
      v-if="!iframe.loaded"
      class="m-5 p-5 text-center"
    >
      <b-spinner
        class="m-5 p-5"
        variant="success"
        size="12rem"
      />
    </div>

    <iframe
      v-show="iframe.loaded" 
      id="housing__data__dashboard"
      ref="housing__data__dashboard"
      :src="iframe.src"
      scrolling="no"
      title="Housing Data"
      rameborder="0"
      allowFullScreen="true"
      style="width:100%; min-height:1450px; border:0; overflow: hidden;"
      @load="load"
    /> -->

    <div class="mb-5 mx-5">
      <div class="text-center">
        <p class="font-semibold pb-4 text-3xl">
          HMIP | FHFL
        </p>
      </div>
     
     
      <div
        class="mt-4 justify-between gap-4 md:flex-row flex mx-20 flex-col mx-auto"
      >
        <div class="md:w-3/3 w-full">
          <div
            style="background-color: #276c43"
            class="flex flex-col gap-y-1 text-white p-1 bg-[#276c43] rounded-lg"
          >
            <div
              v-if="LGA.length == 0"
              class="w-[100%] md:w-[100%] mx-auto"
            >
              <p
                v-if="report == 'Plywood' || report == 'all' "
                class="pb-1 pt-2 text-lg text-white transition-all duration-300 text-center "
              >
                FHFL Housing Data
              </p>
              <button
                class="bg-[#1cfa78] py-2 text-[#262637] px-4 rounded-sm m-2"
                @click="download()"
              >
                Download Data Set
              </button>

              <iframe
                title="FHFL - Housing Data"
                width="1000"
                height="480"
                class="materialsPrice"
                src="https://app.powerbi.com/view?r=eyJrIjoiYjdmNWU5MTMtMzZkMi00NTMwLTgwZDEtNjMxNjhlNDk3MzU1IiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9"
                frameborder="0"
                allowFullScreen="true"
              />
              <!-- <iframe
                title="FHFL - Housing Data"
                width="1000"
                height="480"
                class="materialsPrice"
                src="https://app.powerbi.com/view?r=eyJrIjoiZGY5ZDMzZTctMzJjNy00MDBlLTg5YzMtOTc4NzFiMmU4YTM0IiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9"
                frameborder="0"
                allowFullScreen="true"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "vform";
import TopSection from "../../components/General/TopSection.vue";
import states from "../states.json";
import LGA from "../LGA.json";

export default {
  name: "HousingDashboard",
  components: { TopSection },

  data: () => ({
    nav_title: "Datasets",
    navs: [
      {
        route_name: "home",
        route_title: "Home",
      },
      {
        route_name: "dashboard",
        route_title: "Dashboards",
      },
      {
        route_name: "dashboard.grid3",
        route_title: "FHFL Housing Data",
        active: true,
      },
    ],
    dataset_dir: '../../assets/datasets/',
    states: states,
    originalLGA: LGA,
    LGA: [],
    report: "all",
    visible: true,
    iframe: {
      src: "https://app.powerbi.com/view?r=eyJrIjoiNmNiNmI1MzMtYjI5ZS00ZmUwLThlNTUtYjdiMTU3YmRjNDhmIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9",
      loaded: false,
    },
    form: new Form({
      state: "",
      LGA: "",
      noOfBeds: "",
      quarter: "",
      year: "",
    }),
  }),
  watch: {
    "form.state": function () {
      this.filter();
    },
  },

  methods: {
    load() {
      this.iframe.loaded = true;
    },

    download(){
      var file_path = this.dataset_dir + 'FHFL_HousingData-20241118(page-1_table-1.csv';
      window.open(file_path);
    },
    filter(report) {
      this.report= report;
      let selectedState = this.form.state.stateName?.toLocaleLowerCase();
      let selectedYear = this.form.year;
      let selectedQuarter = this.form.quarter;
      let selectedLGA = this.form.LGA;
      let selectedNoOfBeds = this.form.noOfBeds;

      if (selectedState) {
        this.LGA = this.originalLGA.filter((x) => {
          return x.state?.toLocaleLowerCase() === selectedState;
        });
      } else {
        this.LGA = [];
      }

      if (selectedYear) {
        this.LGA = this.LGA.filter((x) => x.year == selectedYear);
      }
      if (selectedLGA) {
        this.LGA = this.LGA.filter((x) => x.LGA == selectedLGA);
      }
      if (selectedQuarter) {
        this.LGA = this.LGA.filter((x) => x.quarter == selectedQuarter);
      }
      if (selectedNoOfBeds) {
        this.LGA = this.LGA.filter((x) => x.noOfBeds == selectedNoOfBeds);
      }
    },
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
.materialsPrice{
  zoom:1.2
}
</style>
