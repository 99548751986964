<template>
  <section class="sub-page iframe">
    <top-section
      :navs="navs"
      :title="nav_title"
    />

  
    
    <b-row
      cols="1"
      class="p-3 mx-auto col-md-10 my-3 text-center align-items-stretch"
    >
      <b-col>
        <div
          class="mt-2 animated"
          data-animation="fadeInUpShorter"
          data-animation-delay="0.5s"
        >
          <div class="dt-responsive table-responsive">
            <table
              id="simpletable"
              style="width: 90%;"
              class="table table-striped table-bordered nowrap"
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Source</th>
                  <th> Action</th> 
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Housing Data</td>
                  <td>FHFL</td>
                  <td>
                    <button
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FHFL - Housing Data - 20241118.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Building Attributes Breakdown by Wards</td>
                  <td>NPC</td>
                  <td>
                    <button
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NPC - Building attributaaes breakdown by wards - 20241105.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Nigeria-2024-Macroeconomic-Data-Pack </td>
                  <td>Estate Intel</td>
                  <td>
                    <button
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('Estate-Intel - Nigeria-2024-Macroeconomic-Data-Pack 20241210.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>HOUSEHOLD INCOME DATA REQUEST</td>
                  <td>CBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('CBN - HH INCOME DATA REQUEST - 20241126.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>NHDC Data Analytics Program 2020</td>
                  <td>NHDC</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NHDC Data Analytics Program - 2020 - 20241210 v4.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Cons Real Estate Labour 11122024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - cons_realestate_labour 20241211.docx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Consumer Price Index</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Consumer Price Index_1NewSept_2024 20241210.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Nigeria Labour Force Statistics Q1 2024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Nigeria Labour Force Statistics_Q1_2024_Report 20241210.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>LSMS_Integrated_Panel_Survey</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - LSMS_Integrated_Panel_Survey_Report 20241210.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Nigeria Labour Force Statistics Q3 2024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Nigeria Labour Force Statistics_Q3_2023_Report 20241210.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Nigeria Labour Force Statistics Q2 2024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Nigeria Labour Force Statistics_Q2_2024_Report 20241210.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Consumer Price Index_01OCT 2024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Consumer Price Index_1OCT_2024 20241210.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Consumer Price Index_AUG 2024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Consumer Price Index_AUG_2024 20241210.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Q3 GDP 2024</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - Q3 GDP 2024 20241210.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Price of Building Materials 2022</td>
                  <td>CBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NHDC Data Analytics Program - 2020 - 20241210 v4.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Building Materials Data Request Q2 2022</td>
                  <td>CBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('CBN - Building Materials Data Request - Q2 2022.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Numbers of NHP Buildings across the Country</td>
                  <td>FMHUD</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FMHUD - Numbers of NHP buildings across the Country Final 20241106.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Selling prices of the National Housing Programme across the Country</td>
                  <td>FMHUD</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FMHUD - Selling Prices of the National Housing Programme across the Country Excel Final 20241106.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Consumer Price Index MARCH 2024</td>
                  <td />
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS - CONSUMER PRICE INDEX MARCH 2024.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Housing Data</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FHFL - Housing Data - 20241118.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>GDP</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('NBS-GDP.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Prototype Housing Scheme</td>
                  <td>FMHUD</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FMHUD - Prototype Housing Scheme by FMHUD FINAL 20241106.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>FHA  Estates  20241105</td>
                  <td>FHA</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FHA Estates - 20241105.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>2024 World Habitat Day World Press Conference</td>
                  <td>AHCN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('AHCN - 2024 WORLD HABITAT DAY WORLD PRESS CONFERENCE - Data 20241105.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>2024 World Habitat Day World Press Conference</td>
                  <td>AHCN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('AHCN - 2024 WORLD HABITAT DAY WORLD PRESS CONFERENCE 20241105.docx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>API_EN.POP.SLUM.UR.ZS_D2_en_excel_v2_989</td>
                  <td>World Bank</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('World Bank - API_EN.POP.SLUM.UR.ZS_DS2_en_excel_v2_989.xls')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>API_NY.GDP.PCAP.CD_DS2_en_excel</td>
                  <td>World Bank</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('World Bank - API_NY.GDP.PCAP.CD_DS2_en_excel_v2_31806.xls')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Operational Performance September 2024</td>
                  <td>FMBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FMBN - Operational Performance September 2024 - 20241105.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Weekly Prices of Building Materials Q3 2022</td>
                  <td>CBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('CBN - Weekly Prices of Building Materials - Q3 2022')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Housing Condition Submission Q2 2022</td>
                  <td>CBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('CBN - Housing Condition_Submission - Q2 2022')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Prices of Building Materials</td>
                  <td>CBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('CBN-PRICES OF BUILDING MATERIALS-20241030.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>NGA_2023_GHSP- WS</td>
                  <td />
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>GRID3</td>
                  <td />
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('GRID3_NGA_health_facilities_v2_0_784336137303191268.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>General Household Survey 2018-2019</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('ghs_panel_wave4_survey_report.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Nigeria LSS 2018-2019</td>
                  <td>NBS</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('nigeria_nlss2018_2019_report.pdf')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>FCTA</td>
                  <td>FMBN</td>
                  <td>
                    <button 
                      class="text-center w-full py-2 bg-[#1b483e] text-white"
                      @click="download('FCTA - MASTERLIST AS AT JULY 21 - TK20200828.xlsx')"
                    >
                      Download
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Data</th>
                  <th>Source</th>
                  <th> Action</th> 
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      cols="3"
      class="p-3 mx-auto col-md-10 my-3 text-center align-items-stretch"
    >
      <b-col
        v-for="(dataset, index) in datasets_files"
        :key="index"
        class="card-group col-4 d-flex justify-content-center"
      >
        <div
          class="card m-2 mb-4 p-4 cursor-pointer dataset__card animated text-center d-flex justify-content-center"
          data-animation="fadeInDown"
          :data-animation-delay="`1.` + index+1 + `s`"
          @click="handleRouting(dataset, 'download')"
        >
          <div class="p-3 content">
            <h6
              class="m-0 text-wrap"
              v-html="getTitle(dataset.title)"
            />
           
            <p
              v-if="dataset.description"
              class="pt-2"
            >
              {{ dataset.description }}
            </p>
          </div>
        </div>        
      </b-col>
    </b-row>
  </section>
</template>
  
  <script>
  import TopSection from '../../components/General/TopSection.vue'
  import axios from 'axios'
  
  export default {
      name: 'DatasetHome',
      components: { TopSection },
  
      data: () => ({
          nav_title: 'Datasets',
          navs: [
              {
                  route_name: 'home',
                  route_title: 'Home',
              },
              {
                  route_name: 'datasets',
                  route_title: 'Datasets',
                  active: true,
              },
          ],
          dataset_dir: '../../assets/datasets/',
          datasets_links: [],
          datasets_files: [
              {
                title: 'building_materials_price_update',
                slug: 'building_materials_price_update_april_2022_june_2022',
                description: '(APR 2022 - JUNE 2022)',
              },
              {
                title: 'building_materials_price_update',
                slug: 'building_materials_price_update_june_2022_october_2022',
                description: '(JUN 2022 - OCT 2022)',
              },
              {
                title: 'housing_condition_in_nigeria',
                description: '(2022 Survey)',
              },
              {
                title: 'finance_data',
                description: null,
              },
              {
                title: 'household_data',
                description: null,
              },
              {
                title: 'nre_data',
                description: null,
              },
              {
                title: 'price_index',
                description: null,
              },
              {
                title: 'public_sector_data',
                description: null,
              },

          ],
      }),

      methods: {

        download(path){
      var file_path = this.dataset_dir + path;
      window.open(file_path);
    },

          handleRouting(dataset, type) {
              switch (type) {
                  case 'link':
                      this.$router.push({ name: dataset.route_name })
                      break;
                  case 'download':
                      // this.downloadItem(dataset);
                      var file = dataset.slug ?? dataset.title;
                      var file_name = file + '.zip';
                      var file_path = this.dataset_dir + file_name;
                      window.open(file_path);

                      // var link = '';
                      // link = document.createElement("a");
                      // link.href = file_path;
                      // link.download = dataset;
                      // link.download = file;
                      // link.click();
                      break;
              
                  default:
                      break;
              }
          },
  
          async downloadItem(file) {
            const response = await axios.get(file.path, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/zip" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.file_name;
            link.click();
            URL.revokeObjectURL(link.href);
          },

          getTitle(slug)  {
            const title = slug.split('_');
            for(var i = 0; i < title.length; i++) {
              var word = title[i];
              title[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return title.join(' ');
          }
      }
  
  }
</script>
<style scoped>
.iframe {
    overflow-y: hidden;
}
.dataset__card:hover {
    border-color: rgba(92, 164, 122, 0.7);
    box-shadow: 0px 0px 10px 2px rgba(92, 164, 122, 0.6) !important;
    transition: border-color 1s, box-shadow 0.5s;
    background-color: rgb(254, 254, 254);
}
.dataset__card:hover .content {
    border: 1px solid rgba(92, 164, 122, 0.7);
    border-radius: 8px;
}
</style>
  